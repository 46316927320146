import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  Body2,
  ButtonStyles,
  LegalBody,
  LoaderButton,
  Modal,
} from '@pumpkincare/shared/ui';
import { useDeleteUserAch } from '@pumpkincare/user';

// eslint-disable-next-line css-modules/no-unused-class
import modalStyles from '../../account-wrapper-modal-shared.css';
import styles from './delete-direct-deposit-modal.css';

function DeleteDirectDepositModal({ handleCloseModal }) {
  const { mutateAsync: mutateDeleteUserAch, isLoading: isDeletingUserAch } =
    useDeleteUserAch();

  const [error, setError] = useState('');

  function handleSubmit() {
    mutateDeleteUserAch()
      .then(() => handleCloseModal())
      .catch(() => {
        setError('Something went wrong. Please try again or reach out to support.');
      });
  }

  return (
    <Modal
      onClose={handleCloseModal}
      classes={{
        container: modalStyles.modalContainer,
        content: modalStyles.modal,
      }}
      aria-label='Want to remove direct deposit?'
    >
      <h3>Want to remove direct deposit?</h3>
      <h5>Want to remove direct deposit?</h5>

      <div className={modalStyles.modalBody} style={{ textAlign: 'center' }}>
        <Body2 style={{ marginBottom: '24px' }}>
          You will receive physical checks in the mail unless you have direct deposit
          set up.
        </Body2>

        {error ? <LegalBody className={styles.error}>{error}</LegalBody> : null}

        <LoaderButton
          onClick={handleSubmit}
          color='primary'
          isLoading={isDeletingUserAch}
          classes={{ root: styles.submitButton }}
        >
          Revert to Mailed Checks
        </LoaderButton>

        <button
          onClick={handleCloseModal}
          className={classNames(
            styles.submitButton,
            modalStyles.closeModalButton,
            ButtonStyles.tertiary
          )}
        >
          Keep Direct Deposit
        </button>
      </div>
    </Modal>
  );
}

DeleteDirectDepositModal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
};

export default DeleteDirectDepositModal;
