import Paths from '../paths';

const ACCOUNT = `Account`;

const title = {
  [Paths.Billing]: `${ACCOUNT} | Billing`,
  [Paths.Landing]: `${ACCOUNT} | Profile`,
  [Paths.PetCoverage]: `${ACCOUNT} | Pet Plan`,
  [Paths.PetClaims]: `${ACCOUNT} | Claims`,
  [Paths.PetRecords]: `${ACCOUNT} | Records`,
  [Paths.PetClaimSubmission]: `${ACCOUNT} | File a claim`,
  [Paths.PetClaimSuccess]: `${ACCOUNT} | Claim success!`,
  [Paths.PetEstimateSubmission]: `${ACCOUNT} | File an estimate`,
  [Paths.PetEstimateSuccess]: `${ACCOUNT} | Estimate success!`,
  [Paths.Overview]: `${ACCOUNT} | Account Overview`,
  [Paths.Reimbursement]: `${ACCOUNT} | Reimbursement`,
  [Paths.Billing]: `${ACCOUNT} | Billing`,
  [Paths.Support]: `${ACCOUNT} | Support`,
  [Paths.Login]: 'Login',
  [Paths.ForgotPassword]: 'Forgot Password',
  [Paths.Registration]: 'Registration',
};

export default function getPageTitle(pathname) {
  return (
    title[pathname] || 'Pumpkin® Pet Insurance | The best treat is a healthy life™'
  );
}
