import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { analyticsTrack, errorBannerShow } from '@pumpkincare/analytics';
import {
  captureException,
  useBanners,
  usePartnerBranding,
} from '@pumpkincare/shared';
import { ErrorPage } from '@pumpkincare/shared/pages';
import { ErrorBoundary, GlobalBanner, SideNav } from '@pumpkincare/shared/ui';
import { useUserBranding } from '@pumpkincare/user';

import Paths from '../../paths';
import FooterLoggedIn from '../footer-logged-in';
import FooterLoggedOut from '../footer-logged-out';
import NavBar from '../nav-bar';

import styles from './page.css';

const LINKS = [
  { href: Paths.Landing, title: 'Home' },
  { href: Paths.Overview, title: 'Account' },
  { href: Paths.Support, title: 'Contact Us' },
];

function Page(props) {
  const {
    children,
    isPrivate,
    isFooterVisible = true,
    isNavBarVisible = true,
  } = props;

  const { search } = useLocation();
  const { banners, removeBanner } = useBanners();

  const { partner } = queryString.parse(search);
  const {
    data: { secondary_logo_url: secondaryLogo },
  } = usePartnerBranding(partner);

  const {
    data: { secondary_logo_url: userPartnerLogo },
  } = useUserBranding();
  const partnerLogo = secondaryLogo || userPartnerLogo;

  const handleBannerError = useCallback(banner => {
    analyticsTrack(errorBannerShow(banner));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isRedesignedView = isPrivate;

  const FooterComponent = isRedesignedView ? FooterLoggedIn : FooterLoggedOut;
  const NavBarComponent = isRedesignedView ? SideNav : NavBar;

  return (
    <div
      className={classNames(styles.root, {
        [styles.sideNav]: isRedesignedView,
      })}
    >
      {isNavBarVisible ? (
        <NavBarComponent links={LINKS} partnerSrc={partnerLogo} />
      ) : null}

      <div className={styles.content}>
        {banners.map((banner, index) => {
          return (
            <GlobalBanner
              key={index}
              banner={banner}
              onClose={() => removeBanner(banner)}
              onError={handleBannerError}
            />
          );
        })}

        <ErrorBoundary itemRenderer={<ErrorPage />} onError={captureException}>
          <main>{children}</main>
        </ErrorBoundary>

        <footer>{isFooterVisible ? <FooterComponent /> : null}</footer>
      </div>
    </div>
  );
}

Page.propTypes = {
  isFooterVisible: PropTypes.bool,
  isNavBarVisible: PropTypes.bool,
  isPrivate: PropTypes.bool,
};

export default Page;
