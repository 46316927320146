import { useState } from 'react';

import {
  formatPhoneNumber,
  useBooleanInput,
  useTargetState,
  validateEmail,
} from '@pumpkincare/shared';
import {
  Body2,
  ButtonStyles,
  LegalBody,
  LoaderButton,
  Spinner,
  TextField,
} from '@pumpkincare/shared/ui';
import {
  getUserEmail,
  getUserId,
  getUserMailingAddress,
  getUserPhone,
  getUserRatingAddress,
  useMutateUserEmail,
  useUserAddresses,
  useUssr,
} from '@pumpkincare/user';

import MailingAddressUpdateModal from '../../mailing-address-update-modal';

import sharedStyles from '../../account-wrapper-shared.css';
import styles from './contact-information.css';

function ContactInformation() {
  const { data: userData } = useUssr();
  const { data: addressData, isFetching: areAddressesFetching } = useUserAddresses();
  const { mutateAsync: mutateEmail, isLoading: isMutatingEmail } =
    useMutateUserEmail(getUserId(userData));

  const [isAddressModalOpen, toggleAddressModal] = useBooleanInput(false);
  const [isEmailAddressForm, toggleEmailAddressForm] = useBooleanInput(false);
  const [emailEdit, setEmailEdit] = useTargetState('');
  const [emailError, setEmailError] = useState('');

  const email = getUserEmail(userData);
  const phone = getUserPhone(userData);
  const rating = getUserRatingAddress(addressData);
  const mailing = getUserMailingAddress(addressData);

  const isMailingSameAsRating = rating?.street_1 === mailing?.street_1;

  function handleToggleEmailForm() {
    setEmailEdit(email);
    setEmailError('');
    toggleEmailAddressForm();
  }

  function handleSubmitEmailForm(e) {
    e.preventDefault();

    if (emailEdit === '') {
      setEmailError('Email can not be blank');
      return;
    } else if (!validateEmail(emailEdit)) {
      setEmailError('Not a valid email address');
      return;
    }

    setEmailError('');

    mutateEmail(emailEdit)
      .then(() => {
        toggleEmailAddressForm();
      })
      .catch(error => {
        setEmailError(
          error?.response?.data?.message ||
            'Something went wrong. Please try again or reach out to support.'
        );
      });
  }

  function handleEmailChange(event) {
    const newEmail = event.target.value.trim();
    setEmailError('');
    setEmailEdit(newEmail);
  }

  return (
    <>
      <div className={sharedStyles.section}>
        <h5>Contact Information</h5>

        <div className={sharedStyles.cell}>
          {areAddressesFetching ? (
            <Spinner data-testid='addresses-spinner' />
          ) : (
            <>
              <div className={sharedStyles.flex}>
                <div className={sharedStyles.topLine}>
                  <Body2 className={styles.wrap}>
                    <b>Account Address</b>
                    <LegalBody>as shown on policy declarations page</LegalBody>
                  </Body2>

                  <button onClick={toggleAddressModal} className={ButtonStyles.cta}>
                    Edit
                  </button>
                </div>

                <Body2>
                  {rating.street_1} {rating.street_2}, {rating.city},{' '}
                  {rating.state_province} {rating.zipcode}
                </Body2>
              </div>

              {!isMailingSameAsRating ? (
                <div className={sharedStyles.flex}>
                  <Body2 isBold>Mailing Address</Body2>
                  <Body2>
                    {mailing.street_1} {mailing.street_2} {mailing.city},{' '}
                    {mailing.state_province} {mailing.zipcode}
                  </Body2>
                </div>
              ) : null}
            </>
          )}
        </div>

        <div className={sharedStyles.cell}>
          <div className={sharedStyles.flex}>
            <Body2 isBold>Phone Number</Body2>
            <Body2>{formatPhoneNumber(phone)}</Body2>
          </div>
        </div>

        <div className={sharedStyles.cell}>
          <div className={sharedStyles.flex}>
            <div className={sharedStyles.topLine}>
              <Body2 isBold>Email Address</Body2>

              <button onClick={handleToggleEmailForm} className={ButtonStyles.cta}>
                {isEmailAddressForm ? 'Cancel' : 'Edit'}
              </button>
            </div>

            {isEmailAddressForm ? (
              <>
                <form onSubmit={handleSubmitEmailForm}>
                  <TextField
                    value={emailEdit}
                    onChange={handleEmailChange}
                    error={{ errorMessage: emailError }}
                  />
                  <LoaderButton
                    type='submit'
                    color='primary'
                    classes={{ root: styles.formButton }}
                    isLoading={isMutatingEmail}
                  >
                    Save Changes
                  </LoaderButton>
                </form>
              </>
            ) : (
              <Body2>{email}</Body2>
            )}
          </div>
        </div>
      </div>

      {isAddressModalOpen ? (
        <MailingAddressUpdateModal handleCloseModal={toggleAddressModal} />
      ) : null}
    </>
  );
}

export default ContactInformation;
