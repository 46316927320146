/* eslint-disable react/jsx-no-target-blank */
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import queryString from 'query-string';

import { WWW_URL } from '@pumpkincare/config';
import { usePartnerBranding } from '@pumpkincare/shared';
import {
  ARF_MEOW_PHONE_NUMBER,
  CONTACT_EMAIL,
  CONTACT_EMAIL_LINK,
  PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import { LegalBody, Link } from '@pumpkincare/shared/ui';
import { useUserBranding } from '@pumpkincare/user';

import Paths from '../../paths';

import styles from './nav-bar.module.css';

export default function NavBar() {
  const location = useLocation();
  const { search } = useLocation();

  const { partner } = queryString.parse(search);
  const {
    data: { primary_logo_url: partnerLogo },
  } = usePartnerBranding(partner);

  const {
    data: { primary_logo_url: userPartnerLogo },
  } = useUserBranding();

  const isRegisterPage = !!matchPath(location.pathname, {
    path: Paths.Registration,
  });
  const isLoginPage = !!matchPath(location.pathname, {
    path: Paths.Login,
  });

  const partnerLogoClassName = classNames(styles.partnerLogoWrapper, {
    [styles.partnerSectionVisibility]: !isLoginPage && !isRegisterPage,
  });

  return (
    <nav>
      <div className={styles.topMenuContainer}>
        <div>
          <ul className={styles.menuWrapper}>
            <li className={styles.menuItem}>
              <LegalBody className={styles.legalBody}>
                <Link href={PHONE_NUMBER_LINK}>{`${ARF_MEOW_PHONE_NUMBER}`}</Link>
              </LegalBody>
            </li>

            <li className={styles.contactEmail}>
              <LegalBody className={styles.legalBody}>
                <Link href={CONTACT_EMAIL_LINK} className={styles.contactEmail}>
                  {CONTACT_EMAIL}
                </Link>
              </LegalBody>
            </li>

            <li className={styles.liDivider}>
              <div className={styles.vDivider}>&nbsp; </div>
            </li>

            <li className={styles.menuItem}>
              <LegalBody className={styles.legalBody}>
                <Link
                  href={`${WWW_URL}/underwriting-information/`}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Underwriting
                </Link>
              </LegalBody>
            </li>
          </ul>
        </div>
      </div>

      <div className={styles.mainMenuContainer}>
        <div style={{ display: 'flex' }}>
          <Link
            href={WWW_URL}
            target='_blank'
            rel='noopener'
            title='Home'
            tabIndex='-1'
          >
            <div className={styles.logoWrapper}>
              <img
                alt='Pumpkin Logo'
                src='/assets/images/Pumpkin_Logo_Blue.svg'
                className={styles.logo}
              />
            </div>
          </Link>

          {userPartnerLogo || partnerLogo ? (
            <div className={partnerLogoClassName}>
              <div className={styles.separatorColumn} />
              <div className={styles.logoWrapper}>
                <img
                  alt={`${partner || 'partner'} logo`}
                  src={partnerLogo || userPartnerLogo}
                  className={styles.logoPartner}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </nav>
  );
}
