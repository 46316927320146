import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { formatCurrency, formatISODate } from '@pumpkincare/shared';
import {
  ButtonStyles,
  DocumentButton,
  Modal,
  Pagination,
} from '@pumpkincare/shared/ui';

import modalStyles from '../../account-wrapper-modal-shared.css';
import styles from './invoices-modal.css';

const INVOICES_PER_PAGE = 8;

function getRangeOfInvoices(invoices, activePage) {
  const start = (activePage - 1) * INVOICES_PER_PAGE;
  const end = start + INVOICES_PER_PAGE;
  return invoices.slice(start, end);
}

function InvoicesModal({ invoices, handleCloseModal }) {
  const [activePage, setActivePage] = useState(1);

  function handlePageChange(newPage) {
    setActivePage(newPage);
  }

  const needsPagination = invoices.length > INVOICES_PER_PAGE;
  const rangedInvoices = needsPagination
    ? getRangeOfInvoices(invoices, activePage)
    : invoices;
  const range = needsPagination
    ? {
        from: (activePage - 1) * INVOICES_PER_PAGE + 1,
        to: Math.min(activePage * INVOICES_PER_PAGE, invoices.length),
      }
    : {};

  return (
    <Modal
      onClose={handleCloseModal}
      classes={{
        container: modalStyles.modalContainer,
        content: modalStyles.modal,
      }}
      aria-label='All monthly statements'
    >
      <h3>All monthly statements</h3>
      <h5>All monthly statements</h5>

      <div className={classNames(styles.body, modalStyles.modalBody)}>
        {rangedInvoices
          .filter(item => item.status !== 'uncollectible')
          .map(invoice => (
            <DocumentButton
              key={invoice.id}
              title={`${formatISODate(invoice.created_at, {
                inputTimezone: 'local',
                format: 'MMM D, YYYY',
              })} - ${invoice.status === 'paid' ? 'Paid' : 'Past Due'}`}
              subtitle={`${formatCurrency(invoice.total, {
                areCents: true,
              })} - ${invoice.pets.map(({ name }) => name).join(' / ')}`}
              href={invoice.receipt_url}
            />
          ))}

        {needsPagination ? (
          <Pagination
            classes={{ root: styles.pagination }}
            range={range}
            onPageChange={handlePageChange}
            currentPage={activePage}
            count={invoices.length}
          />
        ) : null}

        <button
          onClick={handleCloseModal}
          className={classNames(
            modalStyles.modalButton,
            modalStyles.closeModalButton,
            ButtonStyles.tertiary
          )}
        >
          Close
        </button>
      </div>
    </Modal>
  );
}

InvoicesModal.propTypes = {
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      created_at: PropTypes.string,
      id: PropTypes.string,
      receipt_url: PropTypes.string,
      status: PropTypes.string,
      total: PropTypes.number,
    })
  ).isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default InvoicesModal;
