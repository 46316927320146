import { matchPath, useLocation } from 'react-router-dom';

import { getUserPets, useUssr } from '@pumpkincare/user';

import Paths from '../../app-shell/paths';

export default function usePetFromRoute() {
  const location = useLocation();
  const pathPetId = matchPath(location.pathname, Paths.PetsWrapper)?.params.petId;

  const { data: userData, isFetching: isUserFetching } = useUssr();

  const pets = getUserPets(userData);
  const pet = pets.find(pet => pet.id === pathPetId);

  return { pet, isPetFetching: isUserFetching, pathPetId };
}
