// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".enrolled-plans-section_product_c0537ddb {\n    margin-bottom: 8px;\n}", "",{"version":3,"sources":["webpack://src/user/view/account-wrapper/account-billing/enrolled-plans-section/enrolled-plans-section.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB","sourcesContent":[".product {\n    margin-bottom: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"product": "enrolled-plans-section_product_c0537ddb"
};
export default ___CSS_LOADER_EXPORT___;
