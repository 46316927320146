// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer-logged-out_root_f484119b {\n  background: var(--zeroWhite);\n}", "",{"version":3,"sources":["webpack://src/app-shell/view/footer-logged-out/footer-logged-out.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B","sourcesContent":[".root {\n  background: var(--zeroWhite);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "footer-logged-out_root_f484119b"
};
export default ___CSS_LOADER_EXPORT___;
