import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import classNames from 'classnames';

import { checkPasswordRequirements, useBooleanInput } from '@pumpkincare/shared';
import {
  Body1,
  Body2,
  CheckIcon,
  CloseIcon,
  LegalBody,
  LoaderButton,
  TextField,
  Typography,
  Visibility,
  VisibilityOff,
} from '@pumpkincare/shared/ui';
import {
  getUserEmail,
  getUserFirstName,
  getUserId,
  getUserLastName,
  useUssr,
} from '@pumpkincare/user';

import {
  getUserRegistrationError,
  getUserRegistrationLoading,
} from '../../selectors/user-selectors';
import { setUserRegistrationError } from '../../state/user-ducks';
import checkUserToken from '../../thunks/check-user-token';
import forgotPassword from '../../thunks/forgot-password';

import styles from './registration.css';

const forgotPasswordSuccessCopy =
  'Please check your email for an updated link to access your account!';
const passwordCriteriaCopy = 'Password does not meet criteria';

function Registration() {
  const dispatch = useDispatch();
  const { isTokenError, errorMessage } = useSelector(getUserRegistrationError);
  const isLoading = useSelector(getUserRegistrationLoading);

  const {
    data: { email: loggedInUserEmail, firstName, lastName, userId: loggedInUserId },
  } = useUssr(userData => ({
    email: getUserEmail(userData),
    firstName: getUserFirstName(userData),
    lastName: getUserLastName(userData),
    userId: getUserId(userData),
  }));

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const token = urlSearchParams.get('token');
  const email = urlSearchParams.get('email') ?? loggedInUserEmail;
  const userId = urlSearchParams.get('identifier') ?? loggedInUserId;
  const tokenType = urlSearchParams.get('tokenType') ?? 'password_signup';

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [helperText, setHelperText] = useState('');
  const [passwordVisibility, togglePasswordVisibility] = useBooleanInput(false);
  const [confirmPasswordVisibility, toggleConfirmPasswordVisibility] =
    useBooleanInput(false);
  const [passwordValidation, setPasswordValidation] = useState({ hasErrors: true });
  const [passwordConfirmationError, setPasswordConfirmationError] = useState('');
  const [isPasswordRequirementsOpen, togglePasswordRequirements] =
    useBooleanInput(false);

  const passwordHasAtLeast12char = classNames(styles.passwordBody, {
    [styles.passwordConditionChecked]: passwordValidation.hasAtLeast12Char,
  });

  const passwordHasAtLeastOneLowercaseAndUppercase = classNames(
    styles.passwordBody,
    {
      [styles.passwordConditionChecked]:
        passwordValidation.hasAtLeastOneLowercase &&
        passwordValidation.hasAtLeastOneUppercase,
    }
  );
  const passwordHasAtLeastOneDigit = classNames(styles.passwordBody, {
    [styles.passwordConditionChecked]: passwordValidation.hasAtLeastOneDigit,
  });
  const passwordHasAtLeastOneSpecialChar = classNames(styles.passwordBody, {
    [styles.passwordConditionChecked]: passwordValidation.hasAtLeastOneSpecialChar,
  });

  const validatePassword = () => {
    if (password !== passwordConfirmation) {
      setPasswordConfirmationError('Password does not match.');
      return false;
    }

    const passwordValidation = checkPasswordRequirements(password);
    dispatch(
      setUserRegistrationError({
        errorMessage: !passwordValidation.hasErrors ? '' : passwordCriteriaCopy,
      })
    );
    return !passwordValidation.hasErrors;
  };

  function handleSubmit(e) {
    e.preventDefault();
    if (!isTokenError && validatePassword()) {
      handleRegister();
    } else if (isTokenError) {
      handleSendNewLink();
    }
  }

  function handleRegister() {
    dispatch(checkUserToken(token, userId, email.trim(), password, tokenType)).catch(
      () => {}
    );
  }

  function handleSendNewLink() {
    dispatch(setUserRegistrationError({ errorMessage: '' }));
    setHelperText('');
    dispatch(forgotPassword(email)).then(() => {
      setHelperText(forgotPasswordSuccessCopy);
    });
  }

  function handlePasswordChange(e) {
    setPassword(e.target.value);
    setPasswordValidation(checkPasswordRequirements(e.target.value));
  }

  return (
    <div className={styles.gridRoot}>
      <div className={styles.mainContainer}>
        <h3 className={styles.titleBluesClues}>Now lets set up your account!</h3>

        <form onSubmit={handleSubmit}>
          <div>
            {firstName && lastName && !isTokenError ? (
              <>
                <TextField
                  id='first'
                  label={'First Name'}
                  value={firstName}
                  classes={{ container: styles.textField }}
                  readOnly
                />

                <TextField
                  id='last'
                  label='Last Name'
                  value={lastName}
                  classes={{ container: styles.textField }}
                  readOnly
                />
              </>
            ) : null}

            <TextField
              id='email'
              label='Email'
              value={email}
              classes={{ container: styles.textField }}
              readOnly
            />

            {!isTokenError ? (
              <>
                <TextField
                  id='password'
                  classes={{ container: styles.textField }}
                  label='Password'
                  type={passwordVisibility ? 'text' : 'password'}
                  value={password}
                  onChange={handlePasswordChange}
                  onFocus={togglePasswordRequirements}
                  onBlur={togglePasswordRequirements}
                  endAdornment={{
                    endCustom: (
                      <button
                        aria-label='toggle password visibility'
                        className={styles.visibilityToggle}
                        onClick={togglePasswordVisibility}
                        type='button'
                      >
                        {passwordVisibility ? <Visibility /> : <VisibilityOff />}
                      </button>
                    ),
                  }}
                />

                <TextField
                  id='passwordConfirmation'
                  classes={{ container: styles.textField }}
                  label='Confirm Password'
                  type={confirmPasswordVisibility ? 'text' : 'password'}
                  value={passwordConfirmation}
                  onChange={e => setPasswordConfirmation(e.target.value)}
                  onFocus={() => setPasswordConfirmationError('')}
                  endAdornment={{
                    endCustom: (
                      <button
                        aria-label='toggle password confirmation visibility'
                        className={styles.visibilityToggle}
                        onClick={toggleConfirmPasswordVisibility}
                        type='button'
                      >
                        {confirmPasswordVisibility ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </button>
                    ),
                  }}
                  error={{ errorMessage: passwordConfirmationError }}
                />
              </>
            ) : null}

            <div className={classNames(Typography.legalBody, styles.errorText)}>
              {errorMessage}
            </div>

            {helperText ? <LegalBody>{helperText}</LegalBody> : null}
          </div>

          {isPasswordRequirementsOpen === true ? (
            <div className={styles.passwordDiv}>
              <Body2>Password must include:</Body2>
              <Body1 className={passwordHasAtLeast12char}>
                {passwordValidation.hasAtLeast12Char ? <CheckIcon /> : <CloseIcon />}
                At least 12 characters in length
              </Body1>
              <Body1 className={passwordHasAtLeastOneLowercaseAndUppercase}>
                {passwordValidation.hasAtLeastOneLowercase &&
                passwordValidation.hasAtLeastOneUppercase ? (
                  <CheckIcon />
                ) : (
                  <CloseIcon />
                )}
                At least one UPPERCASE & one lowercase letter
              </Body1>
              <Body1 className={passwordHasAtLeastOneDigit}>
                {passwordValidation.hasAtLeastOneDigit ? (
                  <CheckIcon />
                ) : (
                  <CloseIcon />
                )}
                At least one number
              </Body1>
              <Body1 className={passwordHasAtLeastOneSpecialChar}>
                {passwordValidation.hasAtLeastOneSpecialChar ? (
                  <CheckIcon />
                ) : (
                  <CloseIcon />
                )}

                {`At least one special character, such as: ^ { } ( ) ! @ # % & ~`}
              </Body1>
            </div>
          ) : null}

          <LoaderButton
            color='primary'
            disabled={passwordValidation.hasErrors}
            type='submit'
            isLoading={isLoading}
            classes={{ root: styles.submitButton }}
          >
            {isTokenError ? `Send new password link` : `Submit`}
          </LoaderButton>
        </form>
      </div>
    </div>
  );
}

export default Registration;
