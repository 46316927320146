// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".edit-payment-modal_error_e29f921e {\n    color: var(--rowlfRed);\n    flex: 100%;\n}\n\n.edit-payment-modal_form_1d19b62f {\n    width: 100%;\n    display: flex;\n    flex-wrap: wrap;\n    gap: 24px;\n    margin-bottom: 8px;\n}\n\n.edit-payment-modal_halfWidth_4876bcd5 {\n    flex: 1;\n}", "",{"version":3,"sources":["webpack://src/user/view/account-wrapper/account-billing/edit-payment-modal/edit-payment-modal.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,UAAU;AACd;;AAEA;IACI,WAAW;IACX,aAAa;IACb,eAAe;IACf,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI,OAAO;AACX","sourcesContent":[".error {\n    color: var(--rowlfRed);\n    flex: 100%;\n}\n\n.form {\n    width: 100%;\n    display: flex;\n    flex-wrap: wrap;\n    gap: 24px;\n    margin-bottom: 8px;\n}\n\n.halfWidth {\n    flex: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "edit-payment-modal_error_e29f921e",
	"form": "edit-payment-modal_form_1d19b62f",
	"halfWidth": "edit-payment-modal_halfWidth_4876bcd5"
};
export default ___CSS_LOADER_EXPORT___;
