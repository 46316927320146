import BottomContentLinks from './bottom-content-links';
import Legal from './legal';
import NeedSomeHelp from './need-some-help';

import styles from './footer-logged-out.css';

function FooterLoggedOut() {
  return (
    <footer className={styles.root}>
      <NeedSomeHelp />
      <Legal />
      <BottomContentLinks />
    </footer>
  );
}

export default FooterLoggedOut;
