import * as Sentry from '@sentry/browser';
import { loadStripe } from '@stripe/stripe-js/pure';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { withLDConsumer, withLDProvider } from 'launchdarkly-react-client-sdk';
import { compose } from 'redux';
import Store from 'store';
import expirePlugin from 'store/plugins/expire';

import {
  loadGoogleAnalytics,
  loadOneTrust,
  loadSegment,
  runQueuedSegmentEvents,
} from '@pumpkincare/analytics';
import { ENVIRONMENT, STRIPE_API_KEY } from '@pumpkincare/config';
import { captureException } from '@pumpkincare/shared';

import getPageTitle from './utils/get-page-title';

import packageJson from '../../package.json';

export function configureApp() {
  if (ENVIRONMENT === 'production') {
    Sentry.init({
      environment: ENVIRONMENT,
      release: packageJson.version,
      dsn: 'https://bad58c920572941852d13a6bfc1237ef@sentry.pumpkin.engineering/7',
    });
  }

  initTimeOutScripts();
  Store.addPlugin(expirePlugin);
  Store.removeExpiredKeys();
}

// any scripts that don't need to run on initial load should be here
function initTimeOutScripts() {
  const onSegmentReady = () => {
    runQueuedSegmentEvents();
  };

  setTimeout(() => {
    loadSegment(onSegmentReady, getPageTitle(document.location.pathname));
    loadGoogleAnalytics();
    loadOneTrust();
  }, 200);
}

export function initStripe() {
  return loadStripe(STRIPE_API_KEY).catch(captureException);
}

export function initLaunchDarkly(clientSideID, user) {
  return {
    withLaunchDarkly: compose(
      withLDProvider({ clientSideID, user }),
      withLDConsumer()
    ),
    ldClient: LDClient.initialize(clientSideID, user),
  };
}
