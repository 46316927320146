import classNames from 'classnames';

import { useBooleanInput } from '@pumpkincare/shared';
import { Body2, ButtonStyles, Spinner } from '@pumpkincare/shared/ui';
import {
  getReimbursementTargetAccountNumber,
  getReimbursementTargetNameOnAccount,
  usePayment,
} from '@pumpkincare/user';

import DeleteDirectDepositModal from './delete-direct-deposit-modal';
import DirectDepositSetupModal from './direct-deposit-setup-modal';

import styles from './reimbursement-page.css';

function ReimbursementPage() {
  const {
    data: paymentData,
    isPlaceholderData: isPaymentPlaceholderData,
    isFetching: isPaymentFetching,
  } = usePayment();
  const accountNumber = getReimbursementTargetAccountNumber(paymentData);
  const nameOnAccount = getReimbursementTargetNameOnAccount(paymentData);

  const [isDeleteModalOpen, toggleIsDeleteModalOpen] = useBooleanInput(false);
  const [isSetupModalOpen, toggleIsSetupModalOpen] = useBooleanInput(false);

  return (
    <>
      <div className={styles.root}>
        <h5 className={styles.title}>How to get paid from claims</h5>
        <div className={styles.content}>
          <div className={styles.methodInfo}>
            <Body2 isBold>Reimbursement Method</Body2>
            {isPaymentFetching && isPaymentPlaceholderData ? (
              <Spinner />
            ) : accountNumber ? (
              <Body2>
                Bank Account •••• {accountNumber}&nbsp;&nbsp;|&nbsp;&nbsp;
                {nameOnAccount}
              </Body2>
            ) : (
              <Body2>Mail check</Body2>
            )}
          </div>
          {isPaymentFetching && isPaymentPlaceholderData ? null : (
            <div
              className={classNames(styles.links, {
                [styles.linksSmall]: accountNumber,
              })}
            >
              <button className={ButtonStyles.cta} onClick={toggleIsSetupModalOpen}>
                {accountNumber ? 'Update Bank Details' : 'Add Direct Deposit'}
              </button>
              {accountNumber ? (
                <div>
                  <span className={styles.divider}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                  <button
                    className={ButtonStyles.cta}
                    onClick={toggleIsDeleteModalOpen}
                  >
                    Remove Direct Deposit
                  </button>
                </div>
              ) : null}
            </div>
          )}
        </div>
        <div className={styles.border} />
      </div>

      {isDeleteModalOpen ? (
        <DeleteDirectDepositModal handleCloseModal={toggleIsDeleteModalOpen} />
      ) : null}

      {isSetupModalOpen ? (
        <DirectDepositSetupModal onCancel={toggleIsSetupModalOpen} />
      ) : null}
    </>
  );
}

export default ReimbursementPage;
