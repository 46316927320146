import { useState } from 'react';
import classnames from 'classnames';

import { CLAIM_STATUS_OPTIONS, useClaims } from '@pumpkincare/claims';
import { Pagination, Select, Spinner, Typography } from '@pumpkincare/shared/ui';

import usePetFromRoute from '../../../../hooks/use-pet-from-route';
import ClaimInvoice from '../claim-invoice';

import styles from './track-claims.css';

const ALL_OPTION = { label: 'All', value: 'All' };
const STATUS_OPTIONS = [ALL_OPTION, ...CLAIM_STATUS_OPTIONS];
const PAGE_SIZE_OPTIONS = [
  { label: '10', value: '10' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
];

const TYPE_OPTIONS = {
  insurance: { label: 'Insurance', value: 'insurance' },
  prevent: { label: 'Prevent', value: 'prevent' },
  wellness: { label: 'Wellness', value: 'wellness' },
};

export function formatTypeOptions(pet) {
  const options = [ALL_OPTION];

  if (pet.policies?.length) options.push(TYPE_OPTIONS.insurance);
  if (pet.plans?.length) options.push(TYPE_OPTIONS.prevent);
  if (pet.wellness?.length) options.push(TYPE_OPTIONS.wellness);

  return options;
}

function TrackClaims() {
  const { pathPetId: petId, pet } = usePetFromRoute();

  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [range, setRange] = useState([0, 9]);
  const [filter, setFilter] = useState({ status: '', pet_id: petId });

  const {
    data: { claims: claimsData, contentRange: contentRangeData },
    isFetching: isFetchingClaims,
  } = useClaims(filter, range);

  const { rangeFrom = 0, rangeTo = 9, claimsCount = 10 } = contentRangeData;

  function handlePageChange(newPage) {
    setRange([(newPage - 1) * pageSize, newPage * pageSize - 1]);
    setActivePage(newPage);
  }

  const contentClassName = classnames(styles.claimsContainer, {
    [styles.flexContent]: isFetchingClaims,
  });

  function handlePageSizeChange(value) {
    setRange([0, value - 1]);
    setActivePage(1);
    setPageSize(value);
  }

  function handleFilterChange(value, field) {
    const currentFilter = { ...filter };

    value === ALL_OPTION.value
      ? delete currentFilter[field]
      : (currentFilter[field] = value);

    setFilter(currentFilter);
  }

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <h3 className={styles.header}>Track Claims</h3>

        <div className={styles.content}>
          <div className={classnames(Typography.legalBody, styles.filtersContainer)}>
            <h4 className={styles.marginV}>Filters:</h4>

            <Select
              id='page-size'
              classes={{ root: styles.marginV }}
              defaultValue={PAGE_SIZE_OPTIONS[0]}
              options={PAGE_SIZE_OPTIONS}
              label='Page Size'
              onChange={({ value }) => handlePageSizeChange(value)}
            />

            <Select
              id='claim-type'
              classes={{ root: styles.marginV }}
              defaultValue={ALL_OPTION}
              options={formatTypeOptions(pet)}
              label='Claim Type'
              onChange={({ value }) => handleFilterChange(value, 'claim_type')}
            />

            <Select
              id='claim-status'
              classes={{ root: styles.marginV }}
              defaultValue={ALL_OPTION}
              options={STATUS_OPTIONS}
              label='Claim Status'
              onChange={({ value }) => handleFilterChange(value, 'status')}
            />
          </div>

          <div className={contentClassName}>
            {isFetchingClaims ? <Spinner /> : null}
            {!isFetchingClaims && claimsData.length === 0 ? (
              <span>No Rows</span>
            ) : (
              <div className={styles.pagination}>
                {claimsData.length > 0 && !isFetchingClaims ? (
                  <>
                    <Pagination
                      range={{ from: rangeFrom + 1, to: rangeTo + 1 }}
                      count={parseInt(claimsCount)}
                      currentPage={activePage}
                      onPageChange={handlePageChange}
                    />
                    {claimsData.map(claimData => (
                      <ClaimInvoice {...claimData} key={claimData.id} />
                    ))}
                    <Pagination
                      range={{ from: rangeFrom + 1, to: rangeTo + 1 }}
                      count={parseInt(claimsCount)}
                      currentPage={activePage}
                      onPageChange={handlePageChange}
                    />
                  </>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrackClaims;
