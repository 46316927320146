// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".billing-address-update-modal_error_d3c61c90 {\n    color: var(--rowlfRed);\n    flex: 100%;\n}\n\n.billing-address-update-modal_form_e4e0542f {\n    width: 100%;\n    display: flex;\n    flex-wrap: wrap;\n    gap: 24px;\n    margin-bottom: 8px;\n}\n\n.billing-address-update-modal_halfWidth_24c99c83 {\n    flex: 1;\n}\n\n.billing-address-update-modal_select_9dd1f8ce {\n    margin: unset;\n}", "",{"version":3,"sources":["webpack://src/user/view/account-wrapper/account-billing/billing-address-update-modal/billing-address-update-modal.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,UAAU;AACd;;AAEA;IACI,WAAW;IACX,aAAa;IACb,eAAe;IACf,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI,OAAO;AACX;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".error {\n    color: var(--rowlfRed);\n    flex: 100%;\n}\n\n.form {\n    width: 100%;\n    display: flex;\n    flex-wrap: wrap;\n    gap: 24px;\n    margin-bottom: 8px;\n}\n\n.halfWidth {\n    flex: 1;\n}\n\n.select {\n    margin: unset;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "billing-address-update-modal_error_d3c61c90",
	"form": "billing-address-update-modal_form_e4e0542f",
	"halfWidth": "billing-address-update-modal_halfWidth_24c99c83",
	"select": "billing-address-update-modal_select_9dd1f8ce"
};
export default ___CSS_LOADER_EXPORT___;
