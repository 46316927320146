import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import Store from 'store';

import { ClaimsService } from '@pumpkincare/claims';
import { UserService } from '@pumpkincare/user';

import { userReducer } from '../../user';
import appReducer from './app-ducks';
import segmentTracker from './segment-tracker';

export const history = createBrowserHistory();

export function rootReducer(history) {
  return combineReducers({
    app: appReducer,
    router: connectRouter(history),
    user: userReducer,
  });
}

export function createMemberCenterStore(initialState, extraArgument) {
  const reducer = rootReducer(history);
  const middleware = composeWithDevTools(
    applyMiddleware(
      routerMiddleware(history),
      segmentTracker,

      thunk.withExtraArgument({
        Store,
        claimsService: ClaimsService,
        userService: UserService,

        ...extraArgument,
      })
    )
  );

  return createStore(reducer, initialState, middleware);
}
