// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".contact-information_wrap_6e28d051 {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 0 4px;\n}\n\n.contact-information_wrap_6e28d051 div {\n    line-height: inherit;\n}\n\n.contact-information_formButton_6e6fdd3c {\n    width: 100%;\n    max-width: 400px;\n    margin: 8px auto 0;\n}", "",{"version":3,"sources":["webpack://src/user/view/account-wrapper/account-overview/contact-information/contact-information.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,UAAU;AACd;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".wrap {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 0 4px;\n}\n\n.wrap div {\n    line-height: inherit;\n}\n\n.formButton {\n    width: 100%;\n    max-width: 400px;\n    margin: 8px auto 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "contact-information_wrap_6e28d051",
	"formButton": "contact-information_formButton_6e6fdd3c"
};
export default ___CSS_LOADER_EXPORT___;
