import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useBooleanInput, usePaperlessAgreement } from '@pumpkincare/shared';
import {
  Body2,
  ButtonStyles,
  Checkbox,
  LegalBody,
  LoaderButton,
  Modal,
  Plant,
} from '@pumpkincare/shared/ui';
import { useMutateUserPaperless, useUserProfile } from '@pumpkincare/user';

// eslint-disable-next-line css-modules/no-unused-class
import modalStyles from '../account-wrapper/account-wrapper-modal-shared.css';
import styles from './paperless-modal.css';

function PaperlessModal({ handleCloseModal }) {
  const { data: profileData } = useUserProfile();
  const {
    data: { paperless_terms_url, terms_version },
  } = usePaperlessAgreement();

  const { mutateAsync: mutateUserPaperless, isLoading: isMutatingUserPaperless } =
    useMutateUserPaperless();

  const [isOptedIn, toggleOptIn] = useBooleanInput(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  function handleGoPaperless() {
    if (isOptedIn) {
      mutateUserPaperless({
        ...profileData,
        is_paperless: true,
        terms_version,
      })
        .then(() => handleCloseModal())
        .catch(() => {});
    } else {
      setHasSubmitted(true);
    }
  }

  return (
    <Modal
      onClose={handleCloseModal}
      classes={{ container: modalStyles.modalContainer, content: modalStyles.modal }}
      name='Go Paperless Modal'
    >
      <div className={styles.header}>
        <Plant width={42} height={34} />
        <h3>Go Paperless</h3>
        <h5>Go Paperless</h5>
      </div>

      <div className={modalStyles.modalBody}>
        <Body2 style={{ textAlign: 'center' }}>
          Ditch the paper and access all of your Pumpkin docs easily & securely
          online anytime!
        </Body2>

        <div className={styles.callout}>
          <Checkbox
            label={
              <LegalBody>
                I have read{' '}
                <a href={paperless_terms_url} target='_blank' rel='noreferrer'>
                  Go Paperless with Pumpkin Consent Agreement
                </a>{' '}
                and understand I will no longer receive documents by mail.
              </LegalBody>
            }
            onChange={toggleOptIn}
            checked={isOptedIn}
            isError={hasSubmitted && !isOptedIn}
          />
        </div>

        <LoaderButton
          onClick={handleGoPaperless}
          color='primary'
          isLoading={isMutatingUserPaperless}
          classes={{ root: styles.submitButton }}
        >
          Go Paperless
        </LoaderButton>

        <button
          onClick={handleCloseModal}
          className={classNames(
            styles.submitButton,
            modalStyles.closeModalButton,
            ButtonStyles.tertiary
          )}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
}

PaperlessModal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
};

export default PaperlessModal;
