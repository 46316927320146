// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".invoices-modal_body_345d6f2a {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n}\n\n.invoices-modal_pagination_8006539d {\n    justify-content: center;\n    margin-bottom: 0;\n}", "",{"version":3,"sources":["webpack://src/user/view/account-wrapper/account-billing/invoices-modal/invoices-modal.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ;;AAEA;IACI,uBAAuB;IACvB,gBAAgB;AACpB","sourcesContent":[".body {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n}\n\n.pagination {\n    justify-content: center;\n    margin-bottom: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "invoices-modal_body_345d6f2a",
	"pagination": "invoices-modal_pagination_8006539d"
};
export default ___CSS_LOADER_EXPORT___;
