import { useState } from 'react';

import { useBooleanInput } from '@pumpkincare/shared';
import {
  Body1,
  ButtonStyles,
  FileUpload,
  LegalBody,
  Spinner,
} from '@pumpkincare/shared/ui';
import {
  getUserId,
  uploadFile,
  useMutateMedicalHistory,
  useUssr,
} from '@pumpkincare/user';

import usePetFromRoute from '../../../hooks/use-pet-from-route';

import styles from './pet-records.css';

function PetRecords() {
  const { data: userId } = useUssr(getUserId);
  const { pathPetId: petId, pet } = usePetFromRoute();
  const {
    mutateAsync: mutateMedicalHistory,
    isLoading: isSubmittingMedicalHistory,
  } = useMutateMedicalHistory();

  const [fileList, setFileList] = useState([]);
  const [isUploading, toggleIsUploading] = useBooleanInput();
  const [isSuccess, toggleIsSuccess] = useBooleanInput();
  const [error, setError] = useState('');

  function handleMedicalRecordsUpload() {
    setError('');

    mutateMedicalHistory({ userId, petId, fileList })
      .then(() => {
        toggleIsSuccess();

        setTimeout(() => {
          toggleIsSuccess();
          setFileList([]);
        }, 5000);
      })
      .catch(() => {
        setError('Something went wrong. Please try again or reach out to support.');
      });
  }

  const isSawOnly = pet.wellness.length && !pet.policies.length;

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <Body1 isBold>Add Medical Records</Body1>
        {isSawOnly ? (
          <LegalBody>
            Access {pet.name}'s medical records to gain a comprehensive understanding
            of {pet.genderGrammar.possessive} health condition. This information will
            enable us to better assess {pet.genderGrammar.possessive} overall
            well-being.
          </LegalBody>
        ) : (
          <>
            <LegalBody>
              Help us process your future claims faster! Upload photos, scanned or
              digital copies of all medical history reports received from your vet.
            </LegalBody>
            <div>
              <LegalBody>Don’t have them handy?</LegalBody>
              <LegalBody>
                We will reach out to your vet to obtain your pet’s medical records.
              </LegalBody>
            </div>
          </>
        )}

        <div className={styles.container}>
          <FileUpload
            files={fileList}
            onFileListChange={setFileList}
            error={error}
            onIsUploadingChange={toggleIsUploading}
            onUpload={(file, rawFile) => {
              setError('');
              return uploadFile(file, rawFile, 'medical-record');
            }}
          />

          <button
            className={ButtonStyles.primary}
            role='button'
            disabled={
              isUploading ||
              isSubmittingMedicalHistory ||
              !fileList.length ||
              fileList.some(file => file.error !== undefined)
            }
            onClick={handleMedicalRecordsUpload}
          >
            Save Records
          </button>

          {isSubmittingMedicalHistory || isSuccess ? (
            <div className={styles.overlay}>
              {isSubmittingMedicalHistory ? (
                <Spinner size={100} thickness={10} viewBox='0 0 70 70' />
              ) : null}

              {isSuccess ? (
                <Body1 isBold className={styles.success}>
                  {fileList.length > 1
                    ? 'Files were uploaded successfully!'
                    : 'File was uploaded successfully!'}
                </Body1>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default PetRecords;
