import {
  ARF_MEOW_PHONE_NUMBER,
  CONTACT_EMAIL,
  CONTACT_EMAIL_LINK,
  GLADLY_HOURS,
  openGladly,
  PHONE_ARIA_LABEL,
  PHONE_HOURS,
  PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import { Body1, ChevronRight } from '@pumpkincare/shared/ui';

import MemberCenterBanners from '../member-center-banners';

import styles from './support.css';

function Support() {
  return (
    <>
      <MemberCenterBanners />

      <div className={styles.root}>
        <h5>Contact Us</h5>
        <h2>Contact Us</h2>
        <Body1 isBold className={styles.subtitle}>
          Our team of experts are here to help!
        </Body1>

        <div className={styles.flex}>
          <div className={styles.item}>
            <img src={'/assets/images/contact_phone.svg'} alt='' />
            <div className={styles.contact}>
              <a href={PHONE_NUMBER_LINK} aria-label={PHONE_ARIA_LABEL}>
                Call {ARF_MEOW_PHONE_NUMBER}{' '}
                <ChevronRight width={28} height={26} viewBox='0 0 28 14' />
              </a>
              <p>{PHONE_HOURS[0]}</p>
              <p>{PHONE_HOURS[1]}</p>
            </div>
          </div>

          <div className={styles.item}>
            <img src={'/assets/images/contact_mail.svg'} alt='' />
            <div className={styles.contact}>
              <a href={CONTACT_EMAIL_LINK}>
                Email {CONTACT_EMAIL}{' '}
                <ChevronRight width={28} height={26} viewBox='0 0 28 14' />
              </a>
              <p>We'll respond in a day or less</p>
            </div>
          </div>

          <div className={styles.item}>
            <img src={'/assets/images/contact_hello.svg'} alt='' />
            <div className={styles.contact}>
              <button onClick={openGladly}>
                {' '}
                Chat with us{' '}
                <ChevronRight width={28} height={26} viewBox='0 0 28 14' />
              </button>
              <p>{GLADLY_HOURS}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Support;
