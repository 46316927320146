import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { isCreditCardExpDateValid, useBooleanInput } from '@pumpkincare/shared';
import {
  Body1,
  Body2,
  ButtonStyles,
  LegalBody,
  Spinner,
} from '@pumpkincare/shared/ui';
import {
  formatBillingAddressText,
  getPaymentMethodBrand,
  getPaymentMethodExpMonth,
  getPaymentMethodExpYear,
  getPaymentMethodLast4,
  getUserBillingAddress,
  usePayment,
  useUserAddresses,
} from '@pumpkincare/user';

import MailingAddressUpdateModal from '../../mailing-address-update-modal';
import BillingAddressUpdateModal from '../billing-address-update-modal';
import EditPaymentModal from '../edit-payment-modal';

import sharedStyles from '../../account-wrapper-shared.css';
import styles from './purchased-plans-section.css';

function PurchasedPlansSection({
  isChargedAnnually,
  isUserLapsed,
  hasEmployerWellness,
}) {
  const { hash } = useLocation();

  const { data: addressData, isFetching: isBillingFetching } = useUserAddresses();
  const billingAddress = getUserBillingAddress(addressData);

  const { data: paymentData, isFetching: isPaymentFetching } = usePayment();

  const expMonth = getPaymentMethodExpMonth(paymentData);
  const expYear = getPaymentMethodExpYear(paymentData);
  const brand = getPaymentMethodBrand(paymentData);
  const last4 = getPaymentMethodLast4(paymentData);

  const [isPaymentModalOpen, togglePaymentModal] = useBooleanInput(
    hash === '#edit-payment-method'
  );
  const [isBillingAddressModalOpen, toggleBillingAddressModal] =
    useBooleanInput(false);
  const [isMailingAddressModalOpen, toggleMailingAddressModal] =
    useBooleanInput(false);

  function handleBillingUpdateSuccess() {
    toggleBillingAddressModal();
    toggleMailingAddressModal();
  }

  const isCreditCardInvalid =
    isUserLapsed && !isCreditCardExpDateValid(expMonth, expYear);

  return (
    <div className={sharedStyles.section}>
      <h5>Payment Method</h5>
      <div className={sharedStyles.cell}>
        {isPaymentFetching ? (
          <Spinner data-testid='payments-spinner' />
        ) : (
          <div className={sharedStyles.flex}>
            {hasEmployerWellness ? (
              <Body1 isBold className={styles.product}>
                For plans purchased from Pumpkin
              </Body1>
            ) : null}
            <div className={sharedStyles.topLine}>
              <Body2 isBold>
                {brand} **** {last4}
              </Body2>

              <button onClick={togglePaymentModal} className={ButtonStyles.cta}>
                Edit
              </button>
            </div>

            <Body2 className={classNames({ [styles.error]: isCreditCardInvalid })}>
              Expiration {expMonth}/{expYear}
            </Body2>

            {isCreditCardInvalid ? (
              <LegalBody className={styles.error}>
                Your credit card has expired. Please update your payment method in
                order to process your past due payment.
              </LegalBody>
            ) : null}

            {isPaymentModalOpen ? (
              <EditPaymentModal handleCloseModal={togglePaymentModal} />
            ) : null}
          </div>
        )}
      </div>

      <div className={sharedStyles.cell}>
        {isBillingFetching ? (
          <Spinner data-testid='billing-spinner' />
        ) : (
          <>
            <div className={sharedStyles.flex}>
              <div className={sharedStyles.topLine}>
                <Body2 isBold>Billing Address</Body2>

                <button
                  onClick={toggleBillingAddressModal}
                  className={ButtonStyles.cta}
                >
                  Edit
                </button>
              </div>

              <Body2>{formatBillingAddressText(billingAddress)}</Body2>
            </div>

            {isBillingAddressModalOpen ? (
              <BillingAddressUpdateModal
                handleCloseModal={toggleBillingAddressModal}
                billingAddress={{
                  street_1: billingAddress.street_1,
                  street_2: billingAddress.street_2,
                  city: billingAddress.city,
                  state: billingAddress.state_province,
                  zipcode: billingAddress.zipcode,
                  country: billingAddress.country,
                }}
                handleOnSuccess={handleBillingUpdateSuccess}
              />
            ) : null}

            {isMailingAddressModalOpen ? (
              <MailingAddressUpdateModal
                handleCloseModal={toggleMailingAddressModal}
                header="Is this your pet's new home address too?"
              />
            ) : null}
          </>
        )}
      </div>

      <div className={sharedStyles.cell}>
        <div className={sharedStyles.flex}>
          <div className={sharedStyles.topLine}>
            <Body2 isBold>Billing Cycle</Body2>
          </div>

          <Body2>
            {isChargedAnnually ? 'Annually' : 'Monthly'} (on the billing day of the
            policy)
          </Body2>
        </div>
      </div>
    </div>
  );
}

PurchasedPlansSection.propTypes = {
  isChargedAnnually: PropTypes.bool,
  isUserLapsed: PropTypes.bool,
  hasEmployerWellness: PropTypes.bool,
};

export default PurchasedPlansSection;
