// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".purchased-plans-section_error_9a21b06a {\n    color: var(--rowlfRed);\n}\n\n.purchased-plans-section_product_427725f8 {\n    margin-bottom: 8px;\n}", "",{"version":3,"sources":["webpack://src/user/view/account-wrapper/account-billing/purchased-plans-section/purchased-plans-section.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".error {\n    color: var(--rowlfRed);\n}\n\n.product {\n    margin-bottom: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "purchased-plans-section_error_9a21b06a",
	"product": "purchased-plans-section_product_427725f8"
};
export default ___CSS_LOADER_EXPORT___;
