// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".documents-settings_paperlessModalButton_b05257a2 {\n    width: 100%;\n    max-width: 478px;\n}", "",{"version":3,"sources":["webpack://src/user/view/account-wrapper/account-overview/documents-settings/documents-settings.css"],"names":[],"mappings":"AAEA;IACI,WAAW;IACX,gBAAgB;AACpB","sourcesContent":["@value tablet from '~@pumpkincare/shared/ui/breakpoints';\n\n.paperlessModalButton {\n    width: 100%;\n    max-width: 478px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablet": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + "",
	"paperlessModalButton": "documents-settings_paperlessModalButton_b05257a2"
};
export default ___CSS_LOADER_EXPORT___;
