const Paths = {
  Error: '/error',
  Maintenance: '/maintenance',
  MaintenanceFrame: '/maintenance-frame',

  // auth
  ForgotPassword: '/forgot-password',
  Login: '/login',
  Registration: '/register',

  // Authed Pages
  Landing: '/profile',
  PetsWrapper: '/pets/:petId',
  PetCoverage: '/pets/:petId/plan',
  PetClaims: '/pets/:petId/claims',
  PetClaimSubmission: '/pets/:petId/file-claim',
  PetClaimSuccess: '/pets/:petId/file-claim-success',
  PetEstimateSubmission: '/pets/:petId/file-estimate',
  PetEstimateSuccess: '/pets/:petId/file-estimate-success',
  PetRecords: '/pets/:petId/records',
  AccountWrapper: '/user',
  Overview: '/user/overview',
  Reimbursement: '/user/reimbursement',
  Billing: '/user/billing',
  Support: '/support',
};

export default Paths;
