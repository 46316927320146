// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".account-wrapper_section_4944aa82 {\n    margin-top: 56px;\n}\n\n.account-wrapper_section_4944aa82 h5 {\n    margin: 0;\n}\n\n.account-wrapper_cell_270abaa4 {\n    width: 100%;\n    padding: 24px 0;\n    border-bottom: 1px solid var(--pusheenGray);\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n}\n\n.account-wrapper_flex_35a925e4 {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n    width: 100%;\n}\n\n.account-wrapper_topLine_1f9efabf {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: flex-start;\n}", "",{"version":3,"sources":["webpack://src/user/view/account-wrapper/account-wrapper-shared.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,WAAW;IACX,eAAe;IACf,2CAA2C;IAC3C,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,uBAAuB;AAC3B","sourcesContent":[".section {\n    margin-top: 56px;\n}\n\n.section h5 {\n    margin: 0;\n}\n\n.cell {\n    width: 100%;\n    padding: 24px 0;\n    border-bottom: 1px solid var(--pusheenGray);\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n}\n\n.flex {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n    width: 100%;\n}\n\n.topLine {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: flex-start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "account-wrapper_section_4944aa82",
	"cell": "account-wrapper_cell_270abaa4",
	"flex": "account-wrapper_flex_35a925e4",
	"topLine": "account-wrapper_topLine_1f9efabf"
};
export default ___CSS_LOADER_EXPORT___;
