import { Redirect } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { getIsLoggedIn } from '@pumpkincare/shared';

import Paths from '../../paths';
import MaintenanceModal from '../maintenance-modal';
import Page from '../page';

function PrivatePage(props) {
  const { outageFlag = {} } = useFlags();
  const { type, start, end } = outageFlag;

  if (type === 'scheduled-page')
    return (
      <Redirect
        to={{
          pathname: Paths.MaintenanceFrame,
        }}
      />
    );

  return getIsLoggedIn() ? (
    <>
      {type === 'scheduled-modal' ? (
        <MaintenanceModal timeStart={start} timeEnd={end} />
      ) : null}

      <Page isPrivate {...props} />
    </>
  ) : (
    <Redirect to={Paths.Login} />
  );
}

PrivatePage.propTypes = {
  isFooterVisible: PropTypes.bool,
  isNavBarVisible: PropTypes.bool,
};

export default PrivatePage;
