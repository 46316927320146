import { useState } from 'react';

import { validateEmail } from '@pumpkincare/shared';
import { FORMATTED_PHONE_NUMBER } from '@pumpkincare/shared';
import {
  Body1,
  Circle,
  LegalBody,
  LoaderButton,
  SocialMedia,
  TextField,
} from '@pumpkincare/shared/ui';
import { forgotPassword } from '@pumpkincare/user';

import styles from './forgot-password.css';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  function handleSubmit() {
    setLoading(true);

    if (validateEmail(email)) {
      forgotPassword(email)
        .then(() => {
          setLoading(false);
          setSuccess(true);
        })
        .catch(err => {
          setLoading(false);
          setSuccess(false);
          setErrorMessage(err.message);
        });
    } else {
      setLoading(false);
      setErrorMessage('Invalid e-mail');
    }
  }

  function onEmailChange(event) {
    const newEmail = event.target.value.trim();
    setErrorMessage('');
    setEmail(newEmail);
  }

  return (
    <div className={styles.forgotPassPage}>
      <div className={styles.pageContent}>
        {isSuccess ? (
          <>
            <h1 className={styles.h1}>
              We received your request for your password change!
            </h1>

            <Body1 className={styles.body1}>
              You should receive an email to reset your password within five minutes.
              Check inbox within the next five minutes our your password reset link
              will expire. If you need further assistance feel free to contact our
              Pumpkin Care Team.{' '}
            </Body1>
          </>
        ) : (
          <>
            <h1 className={styles.h1}>Ruh-roh. Don’t know your password?</h1>

            <Body1 className={styles.body1}>
              No worries! We’ll send you an email with a link to create a new
              password. If you don’t have a password, we’ll send you a link to create
              one and set up your account.{' '}
            </Body1>

            <TextField
              id='email'
              value={email}
              label='Please Enter Email'
              onChange={e => onEmailChange(e)}
              classes={{ container: styles.emailField }}
            />

            <LegalBody isBold className={styles.errorText}>
              {errorMessage}
            </LegalBody>

            <LoaderButton
              color='primary'
              classes={{ root: styles.loaderButton }}
              onClick={handleSubmit}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Request Password
            </LoaderButton>
          </>
        )}
        <div>
          <h4 className={styles.h4}>Have questions? We’re a paw away.</h4>

          <Body1>{`${FORMATTED_PHONE_NUMBER} | help@pumpkin.care`} </Body1>

          <SocialMedia
            containerProps={{ className: styles.socialLinks }}
            anchorProps={{ className: styles.socialLinksItem }}
            imageProps={{ className: styles.socialLinksImage }}
          />
        </div>

        <div className={styles.imageContainer}>
          <div className={styles.circles}>
            <Circle
              size={96}
              classes={{ root: styles.circleBlue }}
              fill='bluesCluesBlue'
            />
            <Circle
              size={152}
              classes={{ root: styles.circleRed }}
              fill='cliffordRed'
            />
          </div>

          <div className={styles.catContainer}>
            <img
              src='/assets/images/quote-flow-later-cat.png'
              alt=''
              className={styles.catAsset}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
