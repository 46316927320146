// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".page_root_e9f62306 {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n}\n\n.page_content_8155c785 {\n    display: flex;\n    flex-direction: column;\n}\n\n.page_content_8155c785 main {\n    flex: 1;\n}\n\n.page_content_8155c785 footer {\n    flex: 0;\n}\n\n.page_content_8155c785 {}\n.page_sideNav_f79bd918 {}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + " {\n    .page_root_e9f62306.page_sideNav_f79bd918 {\n        flex-direction: row;\n    }\n\n    .page_content_8155c785 {\n        flex: 1;\n    }\n}\n\n", "",{"version":3,"sources":["webpack://src/app-shell/view/page/page.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,OAAO;AACX;;AAEA;IACI,OAAO;AACX;;AAEA,wBAAU;AACV,wBAAU;;AAEV;IACI;QACI,mBAAmB;IACvB;;IAEA;QACI,OAAO;IACX;AACJ","sourcesContent":["@value tablet from '~@pumpkincare/shared/ui/breakpoints';\n\n.root {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n}\n\n.content {\n    display: flex;\n    flex-direction: column;\n}\n\n.content main {\n    flex: 1;\n}\n\n.content footer {\n    flex: 0;\n}\n\n.content {}\n.sideNav {}\n\n@media tablet {\n    .root.sideNav {\n        flex-direction: row;\n    }\n\n    .content {\n        flex: 1;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablet": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + "",
	"root": "page_root_e9f62306",
	"content": "page_content_8155c785",
	"sideNav": "page_sideNav_f79bd918"
};
export default ___CSS_LOADER_EXPORT___;
