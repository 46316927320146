import { setTrackedCustomProps } from '@pumpkincare/analytics';
import { getUserSelf } from '@pumpkincare/user';

export default function getSelf() {
  return () => {
    return getUserSelf().then(response => {
      setTrackedCustomProps({
        userId: response.users.id,
        userPets: response.users.pets,
        email: response.users.email,
      });
    });
  };
}
