import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import {
  formatCurrency,
  formatISODate,
  sortByTime,
  useBooleanInput,
} from '@pumpkincare/shared';
import {
  Body1,
  Body2,
  ButtonStyles,
  LegalBody,
  Spinner,
} from '@pumpkincare/shared/ui';
import {
  PEP_PLAN_STATUS,
  POLICY_STATUS,
  useInvoices,
  useNextCharges,
  WELLNESS_STATUS,
} from '@pumpkincare/user';

import InvoicesModal from '../invoices-modal';

import sharedStyles from '../../account-wrapper-shared.css';
import styles from './upcoming-payments-section.css';

export function isAPetLapsed(nextChargePets, userPets, billingFeatureFlag = false) {
  return nextChargePets.some(({ id }) => {
    const pet = userPets.find(userPet => userPet.id === id);
    if (billingFeatureFlag) {
      return (
        pet?.policies.some(policy => policy.status === POLICY_STATUS.LAPSED) ||
        pet?.wellness.some(wellness => wellness.status === WELLNESS_STATUS.LAPSED) ||
        pet?.plans.some(plan => plan.status === PEP_PLAN_STATUS.LAPSED)
      );
    } else {
      return pet?.policies.some(policy => policy.status === POLICY_STATUS.LAPSED);
    }
  });
}

function UpcomingPaymentsSection({ isUserFetching, pets }) {
  const { punks1457AdminCustomerBilling } = useFlags();
  const { data: nextChargesData, isFetching: isNextChargesFetching } =
    useNextCharges({ onFetch: sortNextChargeDates });
  const { data: invoicesData } = useInvoices({
    onFetch: sortByTime,
  });

  const [isInvoicesModalOpen, toggleInvoicesModal] = useBooleanInput(false);

  function sortNextChargeDates(nextCharges) {
    return sortByTime(nextCharges, { key: 'next_charge_date', order: 'asc' });
  }

  function transformNextChargesData(nextChargesData, pets) {
    const petIds = pets.map(pet => pet.id);
    const petStatusMap = pets.reduce((map, pet) => {
      if (
        pet.policies.some(policy => policy.status === POLICY_STATUS.LAPSED) ||
        pet.wellness.some(wellness => wellness.status === WELLNESS_STATUS.LAPSED) ||
        pet.plans.some(plan => plan.status === PEP_PLAN_STATUS.LAPSED)
      ) {
        map[pet.id] = 'lapsed';
      }
      return map;
    }, {});

    // Update nextChargesData with pet statuses
    return nextChargesData.reduce((result, charge) => {
      const lapsedPets = [];
      const activePets = [];
      const petProducts = {};

      // Categorize pets and collect products
      charge.pets.forEach(pet => {
        const status = petStatusMap[pet.id] || 'active';
        pet.status = status;
        if (petIds.includes(pet.id)) {
          if (status === 'lapsed') {
            lapsedPets.push(pet);
          } else {
            activePets.push(pet);
          }
        }
      });

      charge.pet_products.forEach(product => {
        if (!petProducts[product.pet_id]) {
          petProducts[product.pet_id] = [];
        }
        petProducts[product.pet_id].push(product);
      });

      // Create objects for lapsed and active pets
      if (lapsedPets.length > 0) {
        result.push({
          ...charge,
          pets: lapsedPets,
          pet_products: lapsedPets.flatMap(pet => petProducts[pet.id] || []),
        });
      }

      if (activePets.length > 0) {
        result.push({
          ...charge,
          pets: activePets,
          pet_products: activePets.flatMap(pet => petProducts[pet.id] || []),
        });
      }

      return result;
    }, []);
  }

  function getProductDescriptions(input, pet_id) {
    const descriptions = {
      wellness: 'Pumpkin Wellness Club',
      insurance: 'Pumpkin Pet Insurance',
      prevent: 'Preventive Essentials',
    };

    const pet = input.find(pet => pet.pet_id === pet_id);

    if (pet) {
      const productDescriptions = pet.product
        .map(product => descriptions[product] || '')
        .filter(Boolean);

      return productDescriptions.join(' & ');
    }

    return '';
  }

  return (
    <div className={sharedStyles.section}>
      <h5 className={classNames(styles.sectionHeader, sharedStyles.topLine)}>
        Upcoming Payments
        {invoicesData?.length ? (
          <button
            className={classNames(styles.invoices, ButtonStyles.secondary)}
            onClick={toggleInvoicesModal}
          >
            View monthly statements
          </button>
        ) : null}
      </h5>

      {isNextChargesFetching ? <Spinner classes={{ root: styles.margin }} /> : null}

      {punks1457AdminCustomerBilling && !isNextChargesFetching && nextChargesData
        ? transformNextChargesData(nextChargesData, pets).map(nextCharge => (
            <div
              className={styles.scheduledItem}
              key={`${nextCharge.next_charge_date}-${nextCharge.product}`}
            >
              <div className={styles.topLine}>
                {!isUserFetching &&
                isAPetLapsed(
                  nextCharge.pets,
                  pets,
                  punks1457AdminCustomerBilling
                ) ? (
                  <LegalBody className={styles.lapsed}>Lapsed</LegalBody>
                ) : null}
                <Body1 isBold className={styles.scheduledDate}>
                  Payments scheduled{' '}
                  {formatISODate(nextCharge.next_charge_date, {
                    format: 'MMM D, YYYY',
                    isGenerated: true,
                  })}
                </Body1>
              </div>

              {nextCharge.pets.map(({ name, id }) => (
                <LegalBody
                  className={styles.petProduct}
                  key={`${nextCharge.next_charge_date}-${id}`}
                >
                  <b>{name}</b> {getProductDescriptions(nextCharge.pet_products, id)}
                </LegalBody>
              ))}

              {nextCharge.past_due ? (
                <div className={styles.upcomingValue}>
                  <Body2>
                    Past Due{' '}
                    <b>{formatCurrency(nextCharge.past_due, { areCents: true })}</b>
                  </Body2>
                </div>
              ) : null}

              {nextCharge.amount ? (
                <div className={styles.upcomingValue}>
                  <Body1>
                    Upcoming Amount{' '}
                    <b> {formatCurrency(nextCharge.amount, { areCents: true })}</b>
                  </Body1>
                </div>
              ) : null}
            </div>
          ))
        : null}

      {!punks1457AdminCustomerBilling && !isNextChargesFetching
        ? nextChargesData.map(nextCharge => (
            <div key={nextCharge.next_charge_date} className={sharedStyles.cell}>
              <div className={sharedStyles.flex}>
                <div className={sharedStyles.topLine}>
                  <Body2 isBold>
                    {nextCharge.pets.map(({ name }) => name).join(' / ')}
                  </Body2>
                  {nextCharge.past_due &&
                  !isUserFetching &&
                  isAPetLapsed(
                    nextCharge.pets,
                    pets,
                    punks1457AdminCustomerBilling
                  ) ? (
                    <LegalBody className={styles.lapsed}>Lapsed</LegalBody>
                  ) : null}
                </div>

                {nextCharge.past_due ? (
                  <Body2>
                    Past Due{' '}
                    <b>{formatCurrency(nextCharge.past_due, { areCents: true })}</b>
                  </Body2>
                ) : null}

                {nextCharge.next_charge_date ? (
                  <Body2>
                    Next payment scheduled{' '}
                    <b>
                      {formatISODate(nextCharge.next_charge_date, {
                        format: 'MMM D, YYYY',
                        isGenerated: true,
                      })}
                    </b>
                  </Body2>
                ) : null}

                <Body2>
                  Next payment amount{' '}
                  <b>{formatCurrency(nextCharge.amount, { areCents: true })}</b>
                </Body2>
              </div>
            </div>
          ))
        : null}

      {invoicesData && isInvoicesModalOpen ? (
        <InvoicesModal
          handleCloseModal={toggleInvoicesModal}
          invoices={invoicesData}
        />
      ) : null}
    </div>
  );
}

UpcomingPaymentsSection.propTypes = {
  isUserFetching: PropTypes.bool,
  pets: PropTypes.array,
};

export default UpcomingPaymentsSection;
