import classNames from 'classnames';

import { PUMPKIN_ASSETS_URL } from '@pumpkincare/config';
import { ButtonStyles, LegalBody, RouteLink } from '@pumpkincare/shared/ui';

import usePetFromRoute from '../../../hooks/use-pet-from-route';
import TrackClaims from './track-claims';

import styles from './pet-claims.css';

function PetClaims() {
  const { pathPetId: petId, pet } = usePetFromRoute();
  const hasInsurance = !!pet?.policies.length;

  return (
    <div className={styles.root}>
      <div className={styles.claimButtons}>
        <RouteLink
          to={`/pets/${petId}/file-claim`}
          className={classNames(styles.claim, ButtonStyles.primary)}
        >
          Start a New Claim
        </RouteLink>

        {hasInsurance ? (
          <RouteLink
            to={`/pets/${petId}/file-estimate`}
            className={classNames(styles.estimate, ButtonStyles.tertiary)}
          >
            Get an Estimate
          </RouteLink>
        ) : null}
      </div>

      <TrackClaims />

      <div className={styles.pdf}>
        <LegalBody>Mailing or faxing your claim?</LegalBody>

        <a
          href={`${PUMPKIN_ASSETS_URL}/pdfs/pumpkin-claim-form.pdf`}
          target='_blank'
          rel='noreferrer'
          className={classNames(ButtonStyles.secondary, styles.download)}
        >
          Download Claim Form (PDF)
        </a>
      </div>
    </div>
  );
}

export default PetClaims;
