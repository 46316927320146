import { useState } from 'react';

import {
  checkPasswordRequirements,
  useBooleanInput,
  useTargetState,
} from '@pumpkincare/shared';
import {
  Body2,
  ButtonStyles,
  LegalBody,
  LoaderButton,
  TextField,
  Typography,
  Visibility,
  VisibilityOff,
} from '@pumpkincare/shared/ui';
import { useLogOut, useMutateUserPassword } from '@pumpkincare/user';

import sharedStyles from '../../account-wrapper-shared.css';
import styles from './login-details.css';

function LoginDetails() {
  const logOut = useLogOut();

  const { mutateAsync: mutatePassword, isLoading: isMutatingPassword } =
    useMutateUserPassword();

  const [isPasswordForm, togglePasswordForm] = useBooleanInput(false);
  const [password, setPassword] = useTargetState('');
  const [newPassword, setNewPassword] = useTargetState('');
  const [confirmPassword, setConfirmPassword] = useTargetState('');
  const [passwordVisibility, togglePasswordVisibility] = useBooleanInput(false);
  const [confirmPasswordVisibility, toggleConfirmPasswordVisibility] =
    useBooleanInput(false);
  const [passwordErrors, setPasswordErrors] = useState({});

  function handleTogglePasswordForm() {
    setPassword('');
    setNewPassword('');
    setConfirmPassword('');
    togglePasswordForm();
  }

  function handleSubmitPasswordForm(e) {
    e.preventDefault();

    const errors = {};
    if (password === '' || newPassword === '' || confirmPassword === '') {
      errors.empty = 'All fields are required.';
    }
    if (newPassword !== confirmPassword) {
      errors.confirm = 'Password does not match.';
    }
    if (checkPasswordRequirements(newPassword).hasErrors) {
      errors.new = 'Password does not meet requirements.';
    }

    setPasswordErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    mutatePassword({ password, newPassword })
      .then(() => {
        togglePasswordForm();
      })
      .catch(error => {
        setPasswordErrors({
          general:
            error?.message ||
            'Something went wrong. Please try again later or contact support',
        });
      });
  }

  function handleLogoutClick() {
    logOut().then(() => {
      global.location.href = '/';
    });
  }

  return (
    <>
      <div className={sharedStyles.section}>
        <h5>Login Details</h5>

        <div className={sharedStyles.cell}>
          <div className={sharedStyles.flex}>
            <div className={sharedStyles.topLine}>
              <Body2 isBold>Password</Body2>

              <button
                onClick={handleTogglePasswordForm}
                className={ButtonStyles.cta}
              >
                {isPasswordForm ? 'Cancel' : 'Update'}
              </button>
            </div>

            {isPasswordForm ? (
              <form
                className={styles.passwordForm}
                onSubmit={handleSubmitPasswordForm}
              >
                <TextField
                  id='password-field'
                  label='Existing Password'
                  type='password'
                  value={password}
                  onChange={setPassword}
                  error={{ hasError: passwordErrors.empty && !password }}
                />
                <TextField
                  id='new-password-field'
                  label='New Password'
                  type={passwordVisibility ? 'text' : 'password'}
                  value={newPassword}
                  onChange={setNewPassword}
                  endAdornment={{
                    endCustom: (
                      <button
                        aria-label='toggle password visibility'
                        className={styles.visibilityToggle}
                        onClick={togglePasswordVisibility}
                        type='button'
                      >
                        {passwordVisibility ? <Visibility /> : <VisibilityOff />}
                      </button>
                    ),
                  }}
                  error={{
                    errorMessage: passwordErrors.new,
                    hasError: passwordErrors.empty && !newPassword,
                  }}
                />
                <TextField
                  id='confirm-password-field'
                  label='Confirm New Password'
                  type={confirmPasswordVisibility ? 'text' : 'password'}
                  value={confirmPassword}
                  onChange={setConfirmPassword}
                  onFocus={() =>
                    setPasswordErrors(prev => ({ ...prev, confirm: '' }))
                  }
                  endAdornment={{
                    endCustom: (
                      <button
                        aria-label='toggle confirm password visibility'
                        className={styles.visibilityToggle}
                        onClick={toggleConfirmPasswordVisibility}
                        type='button'
                      >
                        {confirmPasswordVisibility ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </button>
                    ),
                  }}
                  error={{
                    errorMessage: passwordErrors.confirm,
                    hasError: passwordErrors.empty && !confirmPassword,
                  }}
                />

                <div>
                  <LegalBody>Password must include:</LegalBody>
                  <ul>
                    <li className={Typography.legalBody}>
                      At least 12 characters in length
                    </li>
                    <li className={Typography.legalBody}>
                      At least one UPPERCASE & one lowercase letter
                    </li>
                    <li className={Typography.legalBody}>At least one number</li>
                    <li className={Typography.legalBody}>
                      At least one special character, such as: ^ {} ( ) ! @ # % & ~
                    </li>
                  </ul>
                </div>

                {passwordErrors.general ? (
                  <LegalBody style={{ color: 'var(--rowlfRed)' }}>
                    {passwordErrors.general}
                  </LegalBody>
                ) : null}

                <LoaderButton
                  type='submit'
                  color='primary'
                  isLoading={isMutatingPassword}
                  classes={{ root: styles.formButton }}
                >
                  Update Password
                </LoaderButton>
              </form>
            ) : (
              <Body2>• • • • • • • • </Body2>
            )}
          </div>
        </div>

        <div className={sharedStyles.cell}>
          <div className={sharedStyles.flex}>
            <div className={sharedStyles.topLine}>
              <Body2 isBold>User Session</Body2>

              <button onClick={handleLogoutClick} className={ButtonStyles.cta}>
                Log Out
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginDetails;
