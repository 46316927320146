import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { replace } from 'connected-react-router';
import PropTypes from 'prop-types';
import Store from 'store';

import {
  ARF_MEOW_PHONE_NUMBER,
  capitalize,
  CAT,
  COMPLETED_CLAIM,
  CONTACT_EMAIL,
  CONTACT_EMAIL_LINK,
  DOG,
  GLADLY_HOURS,
  openGladly,
  PHONE_ARIA_LABEL,
  PHONE_HOURS,
  PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import {
  Body1,
  Body2,
  ButtonStyles,
  ChevronLeft,
  ChevronRight,
  RouteLink,
} from '@pumpkincare/shared/ui';
import {
  getPolicyConfig,
  getReimbursementTargetAccountNumber,
  getReimbursementTargetAccountType,
  getUserMailingAddress,
  usePayment,
  useUserAddresses,
  useUserPetPhoto,
} from '@pumpkincare/user';

import { Paths } from '../../../../app-shell';
import usePetFromRoute from '../../../hooks/use-pet-from-route';

import styles from './pet-claim-success.css';

import catPlaceholder from '../../shared/cat_placeholder_fafafa.png';
import dogPlaceholder from '../../shared/dog_placeholder_fafafa.png';
import frame from './frame.svg';
import step1 from './step-1.svg';
import step2 from './step-2.svg';
import step3 from './step-3.svg';

const PLACEHOLDERS = {
  [DOG]: dogPlaceholder,
  [CAT]: catPlaceholder,
};

const CLAIM_COPY = { single: 'claim', plural: 'claims', capital: 'Claim' };
const ESTIMATE_COPY = {
  single: 'estimate',
  plural: 'estimates',
  capital: 'Estimate',
};

function PetClaimSuccess({ isEstimate }) {
  const dispatch = useDispatch();
  const { pet, pathPetId } = usePetFromRoute();
  const { data: userPetPhotoData } = useUserPetPhoto(pathPetId);

  const { data: addressData, isFetching: isAddressLoading } = useUserAddresses();
  const mailingAddress = getUserMailingAddress(addressData);
  const { data: paymentData, isFetching: isPaymentLoading } = usePayment();
  const accountNumber = getReimbursementTargetAccountNumber(paymentData);
  const accountType = getReimbursementTargetAccountType(paymentData);

  const { petId, isEoL, isHighValue, isWellnessClaim, isInsuranceClaim } =
    Store.get(COMPLETED_CLAIM) || {};

  useEffect(() => {
    if (!petId) {
      dispatch(replace(`/pets/${pathPetId}/file-claim`));
    } else if (pathPetId !== petId) {
      dispatch(replace(`/pets/${petId}/file-claim-success`));
    }
  }, [dispatch, pathPetId, petId]);

  const isPetDeceased =
    isEoL || getPolicyConfig(pet.policies)?.cancelled?.isDeceased;

  const type = isEstimate ? ESTIMATE_COPY : CLAIM_COPY;

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <RouteLink to={`/pets/${pet.id}/claims`}>
          <Body2 isBold className={styles.back}>
            <ChevronLeft /> {pet.name}
          </Body2>
        </RouteLink>

        <div className={classNames(styles.headerImage, { [styles.eol]: isEoL })}>
          {isPetDeceased ? (
            <img src={'/assets/images/icons/lifestyle/round-heart.svg'} alt='' />
          ) : (
            <>
              <img
                className={styles.petIcon}
                src={userPetPhotoData?.location || PLACEHOLDERS[pet?.species]}
                alt=''
              />
              <img className={styles.frame} src={frame} alt='' />
            </>
          )}
        </div>

        <h3>
          {type.capital} received!{' '}
          {isPetDeceased ? "We're here to support you." : "We'll take it from here."}
        </h3>
        <Body2 className={styles.subtitle} data-testid='claimSuccess-subtitle'>
          {isPetDeceased
            ? `We understand this is a difficult time. We'll handle your claim with care and keep you informed every step of the way.`
            : isInsuranceClaim && !isWellnessClaim
            ? `
            We’re sending our best wishes to ${pet.name}! While you care for ${pet?.genderGrammar.object}, we'll get to work on your ${type.single} and keep you informed every step of the way.`
            : `Kudos on being an amazing pet parent and prioritizing ${pet.name}’s health! We’ll get to work on your ${type.single} and keep you informed every step of the way.`}
        </Body2>

        {isHighValue ? (
          <Body2
            isBold
            className={styles.subtitle}
            data-testid='claimSuccess-highValue'
          >
            Be aware that higher-value {type.plural} like this one (over $1,000)
            require a bit more time to review. Rest assured, we’ll still work to
            process your claim quickly and carefully. If this {type.single} is
            time-sensitive or urgent, please reach out to our care team for
            assistance.
          </Body2>
        ) : null}

        {!isPetDeceased ? (
          <RouteLink
            to={`/pets/${pet.id}/file-${type.single}`}
            className={classNames(ButtonStyles.secondary, styles.submit)}
          >
            Submit Another {type.capital}
          </RouteLink>
        ) : null}

        <div className={styles.card}>
          <h4>Next steps</h4>

          <div className={styles.steps}>
            <div className={styles.step}>
              <img src={step1} alt='' />
              <Body2 isBold>{type.capital} Review</Body2>
              <Body2>
                We'll review your {type.single} for eligible care. If needed, we'll
                contact you or your vet for more records.
              </Body2>
              <Body2>
                More records to share?{' '}
                <RouteLink to={`/pets/${pathPetId}/records`} className={styles.link}>
                  Upload them here
                </RouteLink>
              </Body2>
            </div>

            <div className={styles.step}>
              <img src={step2} alt='' />
              <Body2 isBold>
                {isWellnessClaim && isInsuranceClaim
                  ? 'Explanation of Benefits & Claims Summary Document'
                  : isInsuranceClaim
                  ? 'Explanation of Benefits'
                  : 'Claims Summary Document'}
              </Body2>
              <Body2>
                {isWellnessClaim && isInsuranceClaim
                  ? "After processing, you'll receive two documents breaking down what Pumpkin covered – one for your insurance claim, and one for your wellness claims. Note that these may arrive at different times."
                  : "After processing, you'll receive a document breaking down what Pumpkin covered."}
              </Body2>
            </div>

            <div className={styles.step}>
              <img src={step3} alt='' />
              <Body2 isBold>Reimbursement</Body2>
              <Body2>{`${
                isInsuranceClaim
                  ? 'Pumpkin will reimburse you for eligible expenses. (Remember that insurance expenses are subject to your deductible & annual limit.)'
                  : 'Pumpkin will reimburse you for eligible wellness expenses. '
              } ${
                !isPaymentLoading
                  ? accountNumber
                    ? "We'll send this to your bank account:"
                    : !isAddressLoading
                    ? "We'll send a check to your mailing address:"
                    : ''
                  : ''
              }`}</Body2>

              <div className={styles.delivery}>
                {!isPaymentLoading ? (
                  accountNumber ? (
                    <Body2>
                      {capitalize(accountType)} ending in {accountNumber}
                    </Body2>
                  ) : !isAddressLoading ? (
                    <>
                      <Body2>
                        {mailingAddress.street_1} {mailingAddress.street_2}
                      </Body2>
                      <Body2>
                        {mailingAddress.city}, {mailingAddress.state_province}{' '}
                        {mailingAddress.zipcode}
                      </Body2>
                    </>
                  ) : null
                ) : null}
              </div>

              <Body2>
                {!isPaymentLoading
                  ? accountNumber
                    ? 'Wrong account information? Contact our care team right away.'
                    : !isAddressLoading
                    ? 'Wrong address? Contact our care team right away.'
                    : ''
                  : ''}
              </Body2>

              {!isPaymentLoading && !accountNumber && !isAddressLoading ? (
                <div className={styles.directDeposit}>
                  <Body2 isBold>Get paid back faster</Body2>
                  <Body2>
                    Activate direct deposit to get quick reimbursement directly to
                    your bank account for future claims.
                  </Body2>

                  <RouteLink
                    to={Paths.Reimbursement}
                    className={ButtonStyles.secondary}
                  >
                    Set Up Direct Deposit
                  </RouteLink>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className={styles.support}>
          <Body1 isBold>Our team of experts are here to help!</Body1>

          <div className={styles.flex}>
            <div className={styles.item}>
              <img src={'/assets/images/contact_phone.svg'} alt='' />
              <div className={styles.contact}>
                <a href={PHONE_NUMBER_LINK} aria-label={PHONE_ARIA_LABEL}>
                  Call {ARF_MEOW_PHONE_NUMBER}{' '}
                  <ChevronRight width={12} height={26} viewBox='0 0 14 14' />
                </a>
                <p>{PHONE_HOURS[0]} |</p>
                <p>{PHONE_HOURS[1]}</p>
              </div>
            </div>

            <div className={styles.item}>
              <img src={'/assets/images/contact_mail.svg'} alt='' />
              <div className={styles.contact}>
                <a href={CONTACT_EMAIL_LINK}>
                  Email {CONTACT_EMAIL}{' '}
                  <ChevronRight width={12} height={26} viewBox='0 0 14 14' />
                </a>
                <p>We'll respond in a day or less</p>
              </div>
            </div>

            <div className={styles.item}>
              <img src={'/assets/images/contact_hello.svg'} alt='' />
              <div className={styles.contact}>
                <button onClick={openGladly}>
                  {' '}
                  Chat with us{' '}
                  <ChevronRight width={12} height={26} viewBox='0 0 14 14' />
                </button>
                <p>{GLADLY_HOURS}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PetClaimSuccess.defaultProps = {
  isEstimate: false,
};

PetClaimSuccess.propTypes = {
  isEstimate: PropTypes.bool,
};

export default PetClaimSuccess;
