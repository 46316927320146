import PropTypes from 'prop-types';

import { Body1, Body2 } from '@pumpkincare/shared/ui';

import sharedStyles from '../../account-wrapper-shared.css';
import styles from './enrolled-plans-section.css';

function EnrolledPlansSection({ enrolledPets, hasInsuranceOrWellness }) {
  const enrollmentType = enrolledPets[0].enrollment_type;
  const vendorName = enrolledPets[0].third_party_entity_name;
  const vendorPhone = enrolledPets[0].vendor_phone;
  return (
    <div className={hasInsuranceOrWellness ? '' : sharedStyles.section}>
      {hasInsuranceOrWellness ? null : <h5>Payment Method</h5>}
      <div className={sharedStyles.cell}>
        <div className={sharedStyles.flex}>
          {hasInsuranceOrWellness ? (
            <Body1 isBold className={styles.product}>
              For plans enrolled in through a partner
            </Body1>
          ) : null}
          <div className={sharedStyles.topLine}>
            <Body2 isBold>
              {enrollmentType === 'employer' ? 'Managed by Employer' : vendorName}
            </Body2>
          </div>
          <Body2>
            To make changes, contact
            {enrollmentType === 'employer' ? (
              ' your employee benefits administrator.'
            ) : (
              <span>
                {' their team at '}
                <a href={`tel:${vendorPhone}`}>{vendorPhone}</a>.
              </span>
            )}
          </Body2>
        </div>
      </div>
    </div>
  );
}

EnrolledPlansSection.propTypes = {
  enrolledPets: PropTypes.arrayOf(
    PropTypes.shape({
      enrollment_type: PropTypes.string,
      third_party_entity_name: PropTypes.string,
      vendor_phone: PropTypes.string,
    })
  ),
  hasInsuranceOrWellness: PropTypes.bool,
};

export default EnrolledPlansSection;
