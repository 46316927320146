import {
  getUserExternalId,
  getUserFirstName,
  getUserLastName,
  useUssr,
} from '@pumpkincare/user';

import { MemberCallout } from '../../../../shared';
import ContactInformation from './contact-information';
import DocumentsSettings from './documents-settings';
import LoginDetails from './login-details';

import styles from './account-overview.css';

function AccountOverview() {
  const { data: userData } = useUssr();
  const firstName = getUserFirstName(userData);
  const lastName = getUserLastName(userData);
  const externalId = getUserExternalId(userData);

  return (
    <div className={styles.root}>
      <MemberCallout
        type='user'
        items={[
          { title: 'Member', copy: `${firstName} ${lastName}` },
          { title: 'Account', copy: externalId },
        ]}
      />

      <ContactInformation />

      <DocumentsSettings />

      <LoginDetails />
    </div>
  );
}

export default AccountOverview;
