const SET_MEDIA_MATCHES = 'app/SET_MEDIA_MATCHES';

const initialState = {
  media: {
    isSmDown: false,
    isMdUp: false,
  },
};

export default function appReducer(state = initialState, { payload, type }) {
  switch (type) {
    case SET_MEDIA_MATCHES: {
      return { ...state, media: payload };
    }

    default:
      return state;
  }
}

export function setMediaMatches(payload) {
  return { type: SET_MEDIA_MATCHES, payload };
}
