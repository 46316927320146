import { Redirect, Route } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { getIsLoggedIn } from '@pumpkincare/shared';
import {
  ErrorPage,
  MaintenanceFrame,
  MaintenancePage,
} from '@pumpkincare/shared/pages';

import {
  AccountWrapper,
  ForgotPassword,
  LoginPage,
  MemberPets,
  PetWrapper,
  Registration,
  Support,
} from '../user';
import Page from './view/page';
import PrivatePage from './view/private-page';
import Paths from './paths';

// these old routes exist in email - if you see this message after Feb 2025, feel free to remove
// not using '*' bc it will hit for all routes, including valid ones
const OLD_ROUTES = ['/account/profile', '/account/pet-policy', '/direct-deposit'];

function Routes() {
  const { outageFlag } = useFlags();
  const isLoggedIn = getIsLoggedIn();

  return (
    <>
      {outageFlag && outageFlag?.type === 'outage' ? (
        <Redirect from={Paths.Login} to={Paths.Maintenance} />
      ) : null}

      <Route path={'/'} exact>
        {isLoggedIn ? (
          <Redirect to={Paths.Landing} />
        ) : (
          <Redirect to={Paths.Login} />
        )}
      </Route>

      <Route path={Paths.Error}>
        <Page>
          <ErrorPage />
        </Page>
      </Route>

      <Route path={Paths.Maintenance}>
        <Page>
          <MaintenancePage />
        </Page>
      </Route>

      <Route path={Paths.MaintenanceFrame}>
        <Page isNavBarVisible={false} isFooterVisible={false}>
          <MaintenanceFrame />
        </Page>
      </Route>

      <Route path={Paths.Login}>
        <Page>
          <LoginPage />
        </Page>
      </Route>

      <Route path={Paths.ForgotPassword}>
        <Page>
          <ForgotPassword />
        </Page>
      </Route>

      <Route path={Paths.Registration}>
        <Page>
          <Registration />
        </Page>
      </Route>

      <Route path={Paths.Landing}>
        <PrivatePage>
          <MemberPets />
        </PrivatePage>
      </Route>

      <Route path={Paths.PetsWrapper}>
        <PrivatePage>
          <PetWrapper />
        </PrivatePage>
      </Route>

      <Route path={Paths.AccountWrapper}>
        <PrivatePage>
          <AccountWrapper />
        </PrivatePage>
      </Route>

      <Route path={Paths.Support}>
        <PrivatePage>
          <Support />
        </PrivatePage>
      </Route>

      <Route path={OLD_ROUTES}>
        {isLoggedIn ? (
          <Redirect to={Paths.Landing} />
        ) : (
          <Redirect to={Paths.Login} />
        )}
      </Route>
    </>
  );
}

export default Routes;
