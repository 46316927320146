import { createTracker, EventTypes } from 'redux-segment';

import { GA_CODE } from '@pumpkincare/config';

import getPageTitle from '../utils/get-page-title';

export default createTracker({
  mapper: {
    ['@@router/LOCATION_CHANGE']: (getState, action) => {
      const path = action.payload.location.pathname || '';
      const title = getPageTitle(path);

      // google analytics only does pageview on initial site load
      if (window.gtag) {
        window.gtag('config', GA_CODE, {
          page_title: title,
          page_path: path,
        });
      }

      const properties = { title };

      return {
        eventType: EventTypes.page,
        eventPayload: {
          name: title,
          properties,
        },
      };
    },
  },
});
