// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login-details_passwordForm_bc8247ce {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n}\n\n.login-details_visibilityToggle_356449fc {\n    background: none;\n    border: none;\n    padding: 0;\n    color: var(--pusheenGray);\n    cursor: pointer;\n}\n\n.login-details_passwordForm_bc8247ce ul {\n    margin: 0;\n    padding-inline-start: 24px;\n}\n\n.login-details_formButton_a69b56e3 {\n    margin: 0;\n    align-self: center;\n    display: block;\n    width: 100%;\n    max-width: 400px;\n}", "",{"version":3,"sources":["webpack://src/user/view/account-wrapper/account-overview/login-details/login-details.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,UAAU;IACV,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,SAAS;IACT,0BAA0B;AAC9B;;AAEA;IACI,SAAS;IACT,kBAAkB;IAClB,cAAc;IACd,WAAW;IACX,gBAAgB;AACpB","sourcesContent":[".passwordForm {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n}\n\n.visibilityToggle {\n    background: none;\n    border: none;\n    padding: 0;\n    color: var(--pusheenGray);\n    cursor: pointer;\n}\n\n.passwordForm ul {\n    margin: 0;\n    padding-inline-start: 24px;\n}\n\n.formButton {\n    margin: 0;\n    align-self: center;\n    display: block;\n    width: 100%;\n    max-width: 400px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"passwordForm": "login-details_passwordForm_bc8247ce",
	"visibilityToggle": "login-details_visibilityToggle_356449fc",
	"formButton": "login-details_formButton_a69b56e3"
};
export default ___CSS_LOADER_EXPORT___;
