import { confirmSignIn, signIn } from 'aws-amplify/auth';
import { push } from 'connected-react-router';
import Store from 'store';

import { configureAmplify } from '@pumpkincare/config';
import { COMPLETE_QUOTE_ID_LS_KEY, setIsLoggedIn } from '@pumpkincare/shared';
import { GRAND_TOTAL_LOCAL_STORAGE_KEY } from '@pumpkincare/shared';
import { putUserPassword } from '@pumpkincare/user';

import { Paths } from '../../app-shell';
import {
  getSelf,
  setUserIsRegistrationLoading,
  setUserRegistrationError,
} from '../index';

export default function checkUserToken(
  token,
  userId,
  email,
  password,
  tokenType = 'password_signup'
) {
  return (dispatch, getState, { userService }) => {
    dispatch(setUserIsRegistrationLoading(true));

    return userService
      .checkUserToken({ token, tokenType })
      .then(() => {
        return dispatch(registerUser(userId, email, password, token, tokenType));
      })
      .catch(error => {
        dispatch(setUserIsRegistrationLoading(false));
        dispatch(
          setUserRegistrationError({
            isTokenError: true,
            errorMessage: `Sorry, your reset password link has expired. Select the button below to request a new password link.`,
          })
        );
        throw error;
      });
  };
}

function registerUser(
  userId,
  email,
  password,
  token,
  tokenType = 'password_signup'
) {
  return dispatch => {
    configureAmplify();

    function confirmSignInFunc() {
      const tokenName =
        tokenType === 'password_signup' ? 'token' : 'vet_survey_token';

      return confirmSignIn({ challengeResponse: `${tokenName}::${token}` });
    }

    function onSuccess() {
      return putUserPassword(userId, password)
        .then(() => {
          return dispatch(getSelf());
        })
        .then(() => {
          Store.remove(GRAND_TOTAL_LOCAL_STORAGE_KEY);
          Store.remove(COMPLETE_QUOTE_ID_LS_KEY);

          setIsLoggedIn(true);
          dispatch(push(Paths.Landing));
        });
    }

    return signIn({
      username: email,
      options: { authFlowType: 'CUSTOM_WITHOUT_SRP' },
    })
      .then(confirmSignInFunc)
      .then(onSuccess)
      .catch(error => {
        if (error.name === 'UserAlreadyAuthenticatedException') {
          return onSuccess();
        } else {
          dispatch(setUserIsRegistrationLoading(false));
          dispatch(
            setUserRegistrationError({
              errorMessage: error.message,
            })
          );
        }
      });
  };
}
