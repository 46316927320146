// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".delete-direct-deposit-modal_submitButton_ea3451b4 {\n    width: 100%;\n}\n\n.delete-direct-deposit-modal_error_9a81b278 {\n    color: var(--rowlfRed);\n    margin-bottom: 24px;\n}", "",{"version":3,"sources":["webpack://src/user/view/account-wrapper/reimbursement-page/delete-direct-deposit-modal/delete-direct-deposit-modal.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;AACvB","sourcesContent":[".submitButton {\n    width: 100%;\n}\n\n.error {\n    color: var(--rowlfRed);\n    margin-bottom: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"submitButton": "delete-direct-deposit-modal_submitButton_ea3451b4",
	"error": "delete-direct-deposit-modal_error_9a81b278"
};
export default ___CSS_LOADER_EXPORT___;
