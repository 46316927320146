// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".account-billing_root_3568a02b {\n    margin-top: 56px;\n    width: 100%;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["desktop"] + " {\n    .account-billing_root_3568a02b {\n        width: var(--w7);\n    }\n}", "",{"version":3,"sources":["webpack://src/user/view/account-wrapper/account-billing/account-billing.css"],"names":[],"mappings":"AAEA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":["@value desktop from '~@pumpkincare/shared/ui/breakpoints';\n\n.root {\n    margin-top: 56px;\n    width: 100%;\n}\n\n@media desktop {\n    .root {\n        width: var(--w7);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desktop": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["desktop"] + "",
	"root": "account-billing_root_3568a02b"
};
export default ___CSS_LOADER_EXPORT___;
