const SET_REGISTRATION_ERROR = 'user/SET_REGISTRATION_ERROR';
const SET_IS_REGISTRATION_LOADING = 'user/SET_IS_REGISTRATION_LOADING';

const initialState = {
  registrationError: {
    isTokenError: false,
    errorMessage: '',
  },
  isRegistrationLoading: false,
};

export default function userReducer(state = initialState, { payload, type }) {
  switch (type) {
    case SET_REGISTRATION_ERROR: {
      return {
        ...state,
        registrationError: {
          ...state.registrationError,
          ...payload,
        },
      };
    }

    case SET_IS_REGISTRATION_LOADING: {
      return {
        ...state,
        isRegistrationLoading: payload,
      };
    }

    default: {
      return state;
    }
  }
}

export function setUserRegistrationError(payload) {
  return {
    type: SET_REGISTRATION_ERROR,
    payload,
  };
}

export function setUserIsRegistrationLoading(payload) {
  return {
    type: SET_IS_REGISTRATION_LOADING,
    payload,
  };
}
